

    import {Component, Prop, Vue} from "vue-property-decorator";

    @Component({
        name: "Action"
    })
    export default class Action extends Vue {
        @Prop(Boolean) stop!: boolean;
        @Prop(Boolean) disabled!: boolean;
        @Prop(Boolean) iconHighlight!: boolean;
        @Prop(String) icon!: string;
        @Prop(String) iconClass!: string;
        @Prop(String) type!: string;
        @Prop({type: String, default: 'left'}) iconPosition!: string;
        @Prop() action!: any;

        loading = false;

        async btnClick(event:any) {
            if (this.stop) {
                event.stopPropagation();
            }
            if (!this.disabled && !this.loading) {
                if (this.action) {
                    this.loading = true;
                    try {
                        await this.action();
                    } catch (e) {
                        this.loading = false;
                        throw (e);
                    }
                    this.loading = false;
                } else {
                    this.$emit('click', event);
                }
            }
        }
    };
