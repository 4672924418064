
    import {Component, Vue, Prop} from 'vue-property-decorator';

    @Component({
        name: "PopOver"
    })
    export default class PopOver extends Vue {
        @Prop() open!: boolean;
        @Prop() placement!: string;
        @Prop() popoverClass!: string;
        @Prop({ type: Number, default: 16}) offset!: number;

        hide() {
            if(this.$refs.popover) {
                (<any>this.$refs.popover).hide();
            }
        }
    }
