
import {Vue, Component, Prop} from 'vue-property-decorator';
import PulseLoader from "@/components/loader/Pulse.vue";
import { RawLocation } from 'vue-router';

@Component({
    components: {PulseLoader}
})
export default class ActionButton extends Vue {
    @Prop({default: false}) loading !: boolean;
    @Prop({default: false}) disabled !: boolean;
    @Prop() goTo !: RawLocation;

    // displayLeftIcon = true;
    // displayRightIcon = true;

    onClick() {
        if(this.disabled || this.loading) return;
        this.$emit('onClick');
        if(this.goTo) {
            this.$router.push( this.goTo );
        }
    }

    beforeMount() {
        // this.displayLeftIcon = !!this.$slots.leftIcon;
        // this.displayRightIcon = !!this.$slots.rightIcon;
    }
}
