
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import _get from "lodash.get";

    @Component({
        name: "Error"
    })
    export default class Error extends Vue {
        @Prop() error!: any;
        messages: any = {
            Email: {
                NotValid: {
                    _default: "Format incorrect."
                }
            },
            Global: {
                ServerError: {
                    _default: "Une erreur est survenue, veuillez réessayer."
                }
            },
            Auth: {
                Username: {
                    Required: {
                        _default: "Utilisateur inexistant.",
                    }
                },
                User: {
                    AlreadyExists: {
                        _default: "Email indisponible.",
                    },
                    NotFound: {
                        _default: "Données de connexion invalides.",
                    }
                },
                Password: {
                    Required: {
                        _default: "Données de connexion invalides."
                    },
                    Invalid: {
                        _default: "Données de connexion invalides."
                    }
                },
                NewPassword: {
                    Invalid: {
                        _default: "Le nouveau mot de passe est vide."
                    },
                    NotMatch: {
                        _default: "Doit correspondre au mot de passe."
                    }
                },
                ResetToken: {
                    Required: {
                        _default: "Demande invalide, veuillez recliquer sur le boutton dans l'email précedement reçu."
                    },
                    Invalid: {
                        _default: "Demande invalide, veuillez recliquer sur le boutton dans l'email précedement reçu."
                    },
                    Expired: {
                        _default: "Demande expirée, veuillez à nouveau faire une demande."
                    },
                }
            },
            Permission: {
                Pending: {
                    _default: "Vous avez déjà fait une demande à cet utilisateur."
                }
            },
            Validation: {
                Any: {
                    Base: {
                        _default: "Ce champ est obligatoire.",
                    },
                    Empty: {
                        _default: "Ce champ est obligatoire."
                    }
                },
                String: {
                    Base: {
                        _default: "Ce champ est obligatoire.",
                        birthDate: "Votre date de naissance est obligatoire."
                    },
                    Email: {
                        _default: "Format incorrect."
                    }
                },
                Object: {
                    AllowUnknown: {
                        _default: "Format incorrect."
                    },
                    Base: {
                        _default: "Ce champ est obligatoire."
                    }
                },
                Date: {
                    Empty: {
                        birthdate: "Vous devez renseigner votre date de naissance."
                    },
                    IsEmpty: {
                        date: "Aucune date n'a été selectionné"
                    }
                },
                Names: {
                    Missing: {
                        firstname: "Le prénom est obligatoire.",
                        lastname: "Le nom est obligatoire.",
                    }
                },
                EmailIsAlreadyUsed: {
                    _html: "L'adresse email saisie est déjà utilisée par un intérimaire. <br>Pour accéder à son profil, cliquez <u><a href=\"{href}\">ici</a></u>.",
                    _link: (this.error && this.error.data && this.error.data.existingUserId)? ("" + this.error.data.existingUserId.toString()):undefined
                },
                Phone: {
                    Invalid: {
                        _default: "Numéro invalide."
                    }
                },
                Email: {
                    Invalid: {
                        _default: "Format incorrect."
                    },
                    NotValid: {
                        _default: "Format incorrect."
                    }
                },
                Password: {
                    MisMatch: {
                        _default: "Doit correspondre au mot de passe."
                    }
                },
                UsernameIsAlreadyUsed: {
                    _default: "Cet identifiant n'est pas disponible. Veuillez renseigner une adresse différente."
                }
            },
            Invalid: {
                Document: {
                    _default: "Une erreur est survenue. Veuillez réessayer en vérifiant que votre fichier excel est correctement formaté.",
                },
                EndDateInferior: {
                    _default: "La date de fin ne peut pas être antérieure à la date de début."
                },
                dateLongerInvalid: {
                    _default: "La durée d'une commande est limitée à 18 mois."
                }
            },
            empty: {
                _default: "Obligatoire.",
                label: "Nom obligatoire.",
            },
            update:{
                preconfiguredViews: {
                    badid: {
                        _default: ""
                    }
                }
            },
            Interim: {
                NotFound: {
                    _default: "Cet intérimaire n'existe pas ou a été supprimé."
                }
            },
            Mission: {
                NotFound: {
                    _default: "Cette mission n'existe pas ou a été supprimée."
                },
                Not: {
                    Found: {
                        _default: "Cette mission n'existe pas ou a été supprimée."
                    }
                }
            },
            Client: {
                NotFound: {
                    _default: "Ce client n'existe pas ou a été supprimé."
                },
                ContractManager: {
                    cantCreate: "Cette entreprise n'a pu être créé dans EVOLIA. Veuillez réessayez dans quelques instants."
                }
            },
            user: {
                already: {
                    exists: {
                        _default: "Cette adresse email n'est pas disponible."
                    }
                    ,
                    collaboratorsExists: {
                        _default: "Cette identifiant existe déjà."
                    }
                },
                defineEmailOrPhone: {
                    _default: "Vous devez au moins renseigner une adresse email ou un numéro de téléphone."
                }
            },
            Period: {
                Same: {
                    _default: ""
                }
            },
            User: {
                NeedToRegister: {
                    _default: "Action indisponible, vous devez suivre la procédure d'inscription que vous avez reçu précédement sur votre boite mail."
                }
            },
            noLogAsToken: {
                _default: "Token incorrect."
            },
            Address: {
                Empty: {
                    _default: "Une ou plusieurs adresses ne sont pas complètes."
                }
            },
            UserContractManager:{
                NotFound: {
                    _default: "Impossible de générer le contrat, l’intérimaire n’est pas synchronisé."
                }
            },
            CompanyContractManager:{
                NotFound: {
                    _default: "Impossible de générer le contrat, le client n’est pas synchronisé."
                }
            },
            ContractCreation:{
                Failed: {
                    _default: "Impossible de générer le contrat, contacter Wizim."
                }
            },
            CandidateIntegration: {
                NoInterview: {
                    _default: "Le candidat n'a pas encore été reçus en entretien ou n'a pas eu de retour"
                },
                NoMission: {
                    _default: "La candidature n'est associée à aucune mission"
                }
            },
            InterviewScheduling: {
                Date: {
                    IsEmpty: {
                        date: "Aucune date n'a été selectionnée"
                    }
                },
                Start: {
                    IsEmpty: {
                        start: "Aucune heure de début n'a été selectionnée"
                    }
                },
                End: {
                    IsEmpty: {
                        end: "Aucune heure de fin n'a été selectionnée"
                    }
                },
                InvalidStart: {
                    _default: "L'heure selectionnée ne peut pas être inférieur à l'heure actuelle"
                }
            },
            JobId: {
                IsEmpty: {
                    _default: "Ce champ est necessaire",
                    jobId: "Vous devez choisir un métier"
                }
            },
            "email not exist": {
                _default: "Email introuvable",
                candidate: "Email du candidat introuvable"
            },
            CancelReason: {
                IsEmpty: {
                    collaboratorId: "Ce champ est obligatoire"
                }
            },
            Name: {
                IsEmpty: {
                    _default: "ce champ est obligatoire",
                    name: "Le nom de l'annonce est obligatoire"
                }
            }
        };


        get errorMessage() {
            // return _get(this.messages, this.error.id);
            return _get(this.$t("errors"), this.error.id);
        }

        get actionError() {
            return this.errorMessage && this.errorMessage[this.error.action];
        }

        get contextError() {
            return this.errorMessage && this.errorMessage[this.error.key];
        }

        get defaultError() {
            return this.errorMessage && this.errorMessage._default;
        }
        get defaultHtmlError() {
            return this.errorMessage && this.errorMessage._html;
        }

        get defaultHtmlErrorMessage() {
            const errorMessage: string = this.errorMessage._html;
            const link = Object.keys(this.error.data).reduce(this.linkReducer, this.errorMessage._link)
            return errorMessage.toString().replace('{href}', link);
        }

        linkReducer(acc:string, currentKey:string) {
            return acc.replace(`{${currentKey}}`, this.error.data[currentKey]);
        }
    }
