
    import {Component, Vue, Prop} from 'vue-property-decorator';

    @Component({
        name: "FormView"
    })
    export default class FormView extends Vue {
        @Prop(String) label!: string;
        @Prop() value!: any;
    }
