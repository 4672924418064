
    import {Component, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";
    import ActionButton from "@/components/ActionButton.vue";
    import NewIcon from "@/components/NewIcon.vue";

    @Component({
        name: "Confirm",
        components: {NewIcon, ActionButton}
    })
    export default class Confirm extends mixins(ModalMixin) {
    }
