
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import PasswordValidator from "password-validator";

    @Component({
        name: "PasswordStrength"
    })
    export default class PasswordStrength extends Vue {
        @Prop(String) password!: string;

        get has8Chars() {
            const schema = new PasswordValidator();

            schema.min(8);
            return schema.validate(this.password);
        }
        get has1Maj() {
            const schema = new PasswordValidator();

            schema.uppercase(1);
            return schema.validate(this.password);
        }
        get has1Number() {
            const schema = new PasswordValidator();

            schema.digits(1);
            return schema.validate(this.password);
        }
        get has1Symbol() {
            const schema = new PasswordValidator();

            schema.symbols(1);
            return schema.validate(this.password);
        }
    }
