<script>

export default {
	name: 'form-container',
	components: {
	},

	props: {
		submitWithReturn: Boolean,
		error: String
	},

	methods: {
		submit(e) {
            // Return an object on submit. Object keys match form field name (HTML attribute)
            const formData = new FormData(e.target);
			this.$emit("submit", Object.fromEntries(formData));
		}
	}
};

</script>

<template>
	<form class="form" @submit.prevent="submit" autocomplete="off" >
		<div v-if="error" class="error" v-html="error"/>
		<slot/>
		<input v-if="submitWithReturn" type="submit" style="display:none;"/>
	</form>
</template>

<style lang="scss">
	.form{
        margin-bottom: $padding;

        &:last-child {
            margin-bottom: 0;
        }

        &.no-padding {
            margin-bottom: 0;
        }
	}
    .error {
        padding-left: 0;
        color: $red;
        padding-bottom: calc($padding / 2) ;
        font-size: 12px;
        border-color: $red;
    }

    .form-group{
        margin-bottom: $padding;
        position: relative;

        &.grey {
            .form-text, .form-select, .form-textarea {
                color: $lightblack;
                border: 1px solid $grey2;
            }
        }

        &.group-stuck {
            &:not(.form-check) {
                flex-direction: column;
            }
            &.form-check {
                input {
                    cursor: pointer;
                }

                label {
                    padding: 10px calc($padding / 3) ;
                    margin-bottom: 0;
                    cursor: pointer;

                    &:hover {
                        color: white;
                    }
                }
            }

            .input {
                display: flex;
                flex-grow: 1;

                input, select, textarea {
                    flex-grow: 1;
                }
            }

            .error {
                position: absolute;
                left: 0px;
                bottom: 100%;
                background-color: red;
                color: #FFFFFF;
                font-weight: $bold;
                padding: 5px 10px 7px 10px;
                border-radius: 4px;
                margin-bottom: 5px;
                max-width: 100%;

                span {
                    position: relative;
                    //z-index: 2;
                }

                &::before {
                    content: "";
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    background-color: red;
                    bottom: -5px;
                    left: 10px;
                    transform: rotateZ(45deg);
                    //z-index: 1;
                }
            }
        }
        label{
            font-size: inherit;
            display: block;
            margin-bottom: calc($padding / 3) ;
            color: $lightblack;

            sup {
                font-size: 0.8em;
                position: relative;
                top: -4px;
            }
        }
        .label-stuck{
            height:50px;
            line-height: 50px;
            border-radius:10px 0 0 10px;
            padding: 0 15px 0 0;
            border-right: 1px solid $grey2;
            margin-bottom: 0;
            white-space: nowrap;
        }
        &.form-error{
            label{
                color:$red;
            }
            select:not(.no-error), textarea, input{
                color:$red !important;
                border:1px solid $red !important;
            }
            .vti__dropdown {
                border:1px solid $red;
            }
        }
        &.form-valid{
            label{
                color:#66ab44;
            }
            input{
                background-color: #ebf9e4;
                color:#66ab44;
                border:1px solid $grey2;
            }
        }
        &:last-child{
            margin-bottom: 0;
        }

        .meta {
            color: $red;
            padding-top: 8px;
            font-size: 11px;
        }
        .Icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            &, svg {
                fill: $grey;
            }

            & + input {
                padding-right: $padding;
            }
        }
    }
    .label {
        display: flex;
        align-items: center;
        margin-bottom: calc($padding / 4) ;
        gap: 4px;
        label {
            margin-bottom: 0;
        }
    }
    .form-text, .form-text-input-input{
        font-size: 14px;
        height: 30px;
        border-radius: 30px;
        padding-left: calc($padding / 2) ;
        display: inline-block;
        border: none;
        width: 100%;
        color: $darkgrey2;
        border: solid 1px $grey2;

        &[readonly] {
            border: none;
        }
    }
    .largeBorder {
        label {
            color: $black;
            //font-weight: $medium;
            font-size: 16px;
        }
        .form-text, .form-text-input-input {
            font-size: 14px;
            height: 42px;
            border-radius: 11px;
            border: 1px solid $grey2;
            background: transparent;
        }
    }

    label {
        color: $black;
        //font-weight: $medium;
        font-size: 16px;
    }
    .form-text, .form-text-input-input, .form-textarea, .form-select {
        font-size: 14px;
        height: 42px;
        border-radius: 8px;
        border: 1px solid $grey2;
        background: $white;
    }

    .noBorder {
        border: none;
    }
    .form-text[type='date'] {
        color: $darkgrey2;
        text-align: center;
        font-weight: $bold;
        border: 1px solid $grey2;
        &::-webkit-calendar-picker-indicator,
        &::-webkit-inner-spin-button {
            color: $white;
            path {
                fill: $white;
            }
        }
    }
    .form-textarea{
        font-size: 14px;
        min-height: 85px;
        padding: 10px;
        display: inline-block;
        width: 100%;
        border-radius: 8px;
        color: $grey;
        border: none;
    }
    .form-select{
        font-size: 13px;
        display: inline-block;
        width: 100%;
        color: $darkgrey2;
        font-weight: $medium;
        background: $white;
        padding: calc($padding / 8)  calc($padding / 3) ;
        border: none;

        option[disabled="disabled"] {
            color: $grey2;
        }
    }
    .form-check, .form-rad{
        display: inline-flex;
        align-items: center;
        .form-checkbox, .form-radio{
            float: left;
            margin-right: calc($padding / 2) ;
            border: 1px solid $grey;
        }
    }
    .form-rad {
        display: flex;
        align-items: center;
        margin-bottom: calc($padding / 4)  !important;
        .form-radio-label {
            margin-bottom: 0 !important;
        }
    }
    .form-check-label {
        margin-bottom: 0 !important;
    }
    input, select{
        outline: none;
    }
    button{
        border: none;
    }
    ::-webkit-input-placeholder { /* Edge */
        font-size: 12px;
        color: $grey2;
        font-weight: $regular;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-size: 12px;
        color: $grey2;
        font-weight: $regular;
    }
    ::placeholder {
        font-size: 12px;
        color: $grey2;
        font-weight: $regular;
    }

    .vue-tel-input {
        border: 1px solid $lightgrey;
        border-radius: 8px;
        position: relative;
        height: 42px;

        &:focus-within{
            box-shadow: none!important;
        }

        .vti__dropdown {
            border-bottom-left-radius: 30px;
            border-top-left-radius: 30px;
            background: $white;
            border-right: 1px solid $lightgrey;
            position: static;

            .vti__dropdown-list.below {
                width: 100%;
                top: 31px;
            }
        }
        .vti__input {
            border-bottom-right-radius: 30px;
            border-top-right-radius: 30px;
            font-size: 14px;
            height: 40px;
            padding-left: calc($padding / 2) ;
            display: inline-block;
            border: none;
            width: 100%;
            color: $darkgrey2;
        }
    }

</style>
