
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    name: "Success"
})
export default class Success extends Vue {
    @Prop(String) text!: string;
    display = false;

    mounted() {
        this.display = true;
    }
}
