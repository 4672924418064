
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import clonedeep from "lodash.clonedeep";
    import 'vue-good-table/dist/vue-good-table.css'
    import { VueGoodTable } from 'vue-good-table';

    @Component({
        name: "SuperTableAts",
        components: {
            VueGoodTable
        }
    })
    export default class SuperTable extends Vue {
        @Prop() headers!: any;
        @Prop() rows!: any;
        @Prop() mode!: string;
        @Prop() isLoading!: boolean;
        @Prop() lineClicked!: any;
        @Prop() totalRecords!: number;
        @Prop() selectOptions!: any;
        @Prop() fixedHeader!: boolean;
        @Prop() maxHeight!: string;
        @Prop() sortOptions!: any;
        @Prop() rowStyleClass!: any;

        defaultNbPerPage = 50;

        $refs!: {
            container: any
        }

        serverParams: any = {};

        paginationOptions: any = {
            enabled: true,
            infoFn: (params:any) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage} / ${params.totalRecords}`,
            nextLabel: this.$t('table.nextLabel'),
            prevLabel: this.$t('table.prevLabel'),
            perPageDropdownEnabled: false,
            perPage: this.defaultNbPerPage
        };

        @Watch('headers', {deep: true})
        onHeaders() {
            this.setData();
        }

        @Watch('rows', {deep: true})
        onRows() {
            this.setData();
        }

        get options() {
            return {
                suppressScrollX: this.fixedHeader,
                suppressScrollY: this.fixedHeader,
            }
        }

        get isSelectable() {
            return this.selectOptions?.enabled;
        }

        get events() {
            return {
                ...(this.lineClicked ? {"on-row-click": this.lineClicked} : {}),
                "on-page-change": this.onPageChange,
                "on-sort-change": this.onSortChange,
                "on-column-filter": this.onColumnFilter,
                "on-per-page-change": this.onPerPageChange
            };
        }

        onSort(header:any) {
            header.onSort(header);
        }

        setData() {
            this.$forceUpdate();
        }

        updateParams(newProps: any) {
            if (!newProps.currentPage) {
                newProps.currentPage = 1;
            }
            if (!newProps.currentPerPage) {
                newProps.currentPerPage = this.defaultNbPerPage;
            }
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        }

        onSortChange(params: any) {
            this.updateParams({
                sort: params[0],
            });
            this.loadItems();
        }

        onPageChange(params: any) {
            console.log('page change');
            this.$refs.container.$el.scrollTop = 0;
            this.updateParams(params);
            this.loadItems();
        }

        onPerPageChange(params: any) {
            console.log('perpage change');
            this.updateParams(params);
            this.loadItems();
        }

        onColumnFilter(params: any) {
            console.log('filter change');
            this.updateParams(params);
            this.loadItems();
        }

        // load items is what brings back the rows from server
        loadItems() {
            this.$emit("onModifier", this.serverParams);
            // getFromServer(this.serverParams).then(response => {
            //     this.totalRecords = response.totalRecords;
            //     this.rows = response.rows;
            // });
        }



        beforeMount() {
            this.setData();
        }
    }
