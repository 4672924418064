
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import Error from "@/components/form/Error.vue";

    @Component({
        name: "GlobalErrors",
        components: {
            Error
        }
    })
    export default class GlobalErrors extends Vue {
        @Prop() errors!: any;

        get displayableErrors() {
            return this.errors.filter((error: any) => {
                return error && Object.keys(error).length;
            })
        }
    }
