<script>
import VuePassword from "vue-password";
import PasswordStrength from "@/components/PasswordStrength.vue";
import VueTimepicker from "@/components/VueTimepicker.vue";
import Datepicker from 'vuejs-datepicker';
import fr from "vuejs-datepicker/src/locale/translations/fr";
import moment from "moment-timezone";
import CurrencyInput from "@/components/CurrencyInput";
import NewIcon from '../NewIcon.vue';

export default {
    name: 'field',
    components: {
        CurrencyInput,
        VuePassword,
        PasswordStrength,
        VueTimepicker,
        Datepicker,
        NewIcon
    },

    props: {
        name: String,
        type: {
            type: String,
            default: "text"
        },
        label: String,
        templateLabel: String,
        placeholder: String,
        value: {},
        error: {},
        options: Array,
        size: Number,
        required: Boolean,
        readonly: Boolean,
        passwordStrength: {},
        labelIcon: String,
        icon: String,
        inputWidth: String,
        dateMin: Number,
        dateMax: Number,
        timeStep: Number,
        autocomplete: {type: Boolean, default: false},
        inlineDate: Boolean,
        disabled: Boolean,
        mask: String,
        contractManagerBlocked: Boolean,
        contractManagerBlockedNoWarning: Boolean
    },

    data() {
        return {
            keepInBounds: true,
            linkUrl: null,
            linkMenuIsActive: false,
            score: 0,
            fr
        };
    },

    computed: {
        groupClass() {
            return "form-group"
                + ((this.type === "checkbox") ? " form-check" : "")
                + ((this.type === "radio") ? " form-rad" : "")
                + (this.error ? " form-error" : "")
        },
        labelClass() {
            return "";
        },

        theLabel() {
            if (this.required) {
                return this.label + '<span style="color: #2051F8;"> *</span>';
            } else {
                return this.label;
            }
        },
    },

    methods: {
        changed(e) {
            if (e && e.target) {
                this.$emit("input", e.target?.value);
                this.$nextTick(() => {
                    this.$emit("change", e.target?.value);
                })
            } else {
                this.$emit("input", e);
                this.$nextTick(() => {
                    this.$emit("change", e);
                })
            }
        },

        onPassword(password) {
            this.$emit("input", password);
            this.score = this.passwordStrength(password);
            this.$nextTick(() => {
                this.$emit("change", password);
            })
        },

        checked(e) {
            this.$emit("input", e.target.checked);
        },

        radiohead(e) {
            this.$emit("input", e.target.value);
        },

        blurEvent() {
            this.$emit('blur');
        },
        focusEvent() {
            this.$emit('focus');
        },

        dateChanged(e) {
            this.$emit("input", moment(e).valueOf());
            this.$nextTick(() => {
                this.$emit("change", moment(e).valueOf());
            })
        },

        txtDateChanged(e) {
            if (e && e.target) {
                this.$emit("input", moment(e.target.value).valueOf());
                this.$nextTick(() => {
                    this.$emit("change", moment(e.target.value).valueOf());
                })
            }
        },

        timeChanged(e) {
            this.$emit("input", e);
            this.$nextTick(() => {
                this.$emit("change", e);
            })
        },

        telChanged(e) {
            this.$emit("input", e);
            this.$nextTick(() => {
                this.$emit("change", e);
            })
        },

        priceChanged(e) {
            this.$emit("input", e);
            this.$nextTick(() => {
                this.$emit("change", e);
            })
        },
    },
};

</script>

<template>
    <div v-if="type==='checkbox'" :class="groupClass">
        <input type="checkbox" class="form-checkbox" :id="name" :name="name" @change="checked" :checked="value"
               @blur="blurEvent">
        <label v-if="label" class="form-check-label" :for="name">{{ label }}</label>
    </div>
    <div v-else-if="type==='radio'" :class="groupClass">
        <input type="radio" class="form-radio" :id="value" :name="name" @change="radiohead" :value="value"
               @blur="blurEvent">
        <label v-if="label" class="form-radio-label" :for="value">{{ label }}</label>
    </div>
    <div v-else :class="groupClass">
        <div class="input">
            <div class="label" v-if="labelIcon">
                <new-icon :icon="labelIcon"/>
                <label v-if="label" :class="labelClass" :for="name" v-html="theLabel"/>
            </div>
            <label v-if="!labelIcon && label && type != 'template-area'" :class="labelClass" :for="name"
                   v-html="theLabel"/>
            <icon v-if="icon" :icon="icon"/>
            <div :class="'value ' + (contractManagerBlocked? 'cm-blocked':'')" v-if="type === 'read' && contractManagerBlocked" v-html="value || 'Non renseigné'" />
            <div v-if="contractManagerBlocked && !contractManagerBlockedNoWarning" class="cm-blocked-explanation">
                {{ $t('contractManager.blockedField') }}
            </div>
            <template v-if="!contractManagerBlocked">
                <div :class="'value ' + (contractManagerBlocked? 'cm-blocked':'')"
                     v-if="type === 'read' || contractManagerBlocked" v-html="value"/>
                <input v-if="type=='txt-date'" type="text" :id="name" :name="name" class="form-text"
                       :placeholder="$t('date.format')"
                       v-mask="$t('date.mask')"
                       @input="changed" :value="value" :size="size" @blur="blurEvent"/>
                <Datepicker v-if="type=='date'"
                            :id="name"
                            :name="name"
                            input-class="form-text"
                            :monday-first="true"
                            :placeholder="placeholder || $t('date.format')"
                            :language="fr"
                            :format="$t('date.formatDatepicker')"
                            :inline="inlineDate"
                            @input="dateChanged"
                            :value="value"/>
                <vue-tel-input :defaultCountry="'fr'"
                               :onlyCountries="['fr']"
                               v-if="type=='tel'"
                               :id="name"
                               :name="name"
                               mode="international"
                               :placeholder="placeholder"
                               :inputOptions="{placeholder: '', autocomplete: 'off', id:name, name: name}"
                               :validCharactersOnly="true"
                               @input="telChanged"
                               :disabled="readonly"
                               :value="value" :size="size" @blur="blurEvent"/>
                <input :readonly="readonly" v-if="type=='text'" :type="type" :id="name" :name="name"
                       :disabled="disabled"
                       :autocomplete="autocomplete ? 'on' : 'off'" :required="required"
                       class="form-text" :placeholder="placeholder" @input="changed" :value="value" :size="size"
                       @blur="blurEvent" @focus="focusEvent" @keydown.enter="$emit('enter')">
                <input :readonly="readonly" v-if="type=='number'" type="text" :id="name" :name="name" class="form-text"
                       :placeholder="placeholder"
                       v-mask="mask"
                       @input="changed" :value="value" :size="size" @blur="blurEvent">
                <currency-input v-if="type=='price'" :value="value" @input="priceChanged" :options="{
                    locale: 'fr-FR',
                    currency: 'EUR',
                    currencyDisplay: 'symbol',
                    precision: 2,
                    hideCurrencySymbolOnFocus: true,
                    hideGroupingSeparatorOnFocus: true,
                    hideNegligibleDecimalDigitsOnFocus: true,
                    autoDecimalDigits: false,
                    exportValueAsInteger: true,
                    autoSign: true,
                    useGrouping: true
                }" />
                <input :readonly="readonly" v-if="type=='rawpassword'" :type="'password'" :id="name" :name="name"
                       :autocomplete="autocomplete ? 'on' : 'off'"
                       class="form-text" :placeholder="placeholder" @input="changed" :value="value" :size="size"
                       @blur="blurEvent">
                <VuePassword
                    v-if="type === 'password'"
                    :classes="'form-text'"
                    :strength="score"
                    :strengthMessages="['', '', '', '', '']"
                    @input="onPassword" :value="value"
                    type="password"
                />
                <select v-if="type=='select'" :id="name" :name="name" class="form-select" @change="changed"
                        :value="value"
                        :readonly="readonly"
                        :placeholder="placeholder"
                        :size="size">
                    <option v-for="(option, index) in options" :key="'option' + option.value + index"
                            :value="option.value"
                            :disabled="option.disabled"
                            :selected="option.selected || option.value === value"
                            :hidden="option.hidden">{{ option.label }}
                    </option>
                </select>
                <div class="template-select" v-if="type=='template-area'">
                    <label v-if="!labelIcon && label" :class="labelClass" :for="name" v-html="theLabel"/>
                    <div>
                        <label v-if="!labelIcon && label" :class="labelClass" :for="name" v-html="templateLabel"/>
                        <select :id="name" class="form-select no-error" @change="changed" :value="value"
                                :placeholder="placeholder"
                                :size="size">
                            <option v-for="(option, index) in options" :key="'option' + option.value + index"
                                    :value="option.value"
                                    :disabled="option.disabled"
                                    :selected="option.selected">{{ option.label }}
                            </option>
                        </select>
                    </div>
                </div>
                <textarea v-if="type=='textarea' || type=='template-area'" :id="name" :name="name" class="form-textarea"
                          @input="changed"
                          :value="value" :placeholder="placeholder" :cols="size" @blur="blurEvent"
                          :disabled="readonly" :required="required" />
                <vue-timepicker v-if="type==='time'"
                                auto-scroll
                                manual-input
                                hide-clear-button
                                :minute-interval="5"
                                :disabled="readonly"
                                input-class="form-text"
                                :input-width="inputWidth"
                                :format="$t('date.time2')"
                                :value="value"
                                @input="timeChanged"
                />
            </template>
        </div>
        <password-strength v-if="type === 'password'" :password="value"/>
        <collapse-transition mode="out-in" :duration="125">
            <div class="meta" v-if="error">
                <error :error="error"/>
            </div>
        </collapse-transition>
    </div>
</template>

<style lang="scss">
input {
    // outline: solid 1.5px $grey2;
    // outline-offset: -1.5px;
    color: $neutral-900;
    &::placeholder {
        color: $grey;
    }
}

.form-select {
    outline: solid 1.5px $grey2;
    outline-offset: -1.5px;
    &::placeholder {
        color: $grey;
    }
}

.form-textarea {
    border: 1px solid $neutral-300;
    color: $neutral-900;
    &:disabled {
        resize: none;
    }
    &::placeholder {
        color: $grey;
    }
}

.value.cm-blocked {
    outline: #F5F6FA solid 1.5px;
    outline-offset: -1.5px;
    color: #7A899E;
    font-weight: 500;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    border-radius: 30px !important;
    padding-left: 15px;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.cm-blocked-explanation {
    margin-top: 5px;
    color: $grey2;
    font-size: 10px;
    line-height: 1.2em;
    padding: 0 10px;
}

.vue-tel-input {

    & > input {
        height: 100% !important;
    }
}
</style>
