
    import {Component, Prop, Vue} from 'vue-property-decorator';

    @Component({
        name: "Card"
    })
    export default class Card extends Vue {
        @Prop(Boolean) disableScroll !: boolean;
        get scrollDisabled() {
            return this.disableScroll;
        }

        get scrollOptions() {
            return {
                suppressScrollX: this.scrollDisabled,
                suppressScrollY: this.scrollDisabled
            };
        }
    }
