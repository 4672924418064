
    import {Component, Vue, Prop} from 'vue-property-decorator';

    @Component({
        name: "ModalMixin"
    })
    export default class ModalMixin extends Vue {
        name: string = "";

        @Prop(Boolean) loading!: boolean;

        @Prop(String) submitBtnText!: string;
        @Prop({
            type : Boolean,
            default: false,
        }) disableSubmitBtn!: boolean;

        @Prop(String) cancelBtnText!: string;
        @Prop({
            type : Boolean,
            default: false,
        }) disableCancelBtn!: boolean;

        @Prop(String) title!: string;

        @Prop({
            type : Boolean,
            default: true,
        }) showCloseButton!: boolean;

        @Prop({
            type : Boolean,
            default: true,
        }) showHeader!: boolean;

        @Prop() width: number | undefined;
        get modalWidth() {
            return this.width ? this.width + "px" : '600px';
        }

        @Prop(String) modalName!: string;

        @Prop({
            type: Boolean,
            default: true
        }) clickToClose!: boolean;

        cancel() {
            this.$emit("closed");
            this.$modal.hide(this.name);
        }

        validate(callback?: any) {
            this.$emit("submit", this.$data, callback);
        }

        beforeOpen() {
            this.$emit("beforeOpen", this.$data);
        }
        opened() {
            this.$emit("opened", this.$data);
        }
        beforeClose() {
            this.$emit("beforeClose", this.$data);
        }
        closed() {
            this.$emit("closed", this.$data);
        }

        beforeMount() {
            if (this.modalName) {
                this.name = this.modalName;
            }
        }
    }
