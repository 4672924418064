"use strict";

import { Commit, Dispatch } from "vuex";
import { ICandidate, ICandidateDB, IJobOffer, IJobOfferDB } from "@/types";
import { ECandidateOfferStatus } from '@/data';

const actions = {

    register({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/auth/register', 'POST', data);
    },

    registerInvitation({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/user/registration/' + data.token, 'POST', data);
    },

    registerAgency({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/agencies/registration', 'POST', data);
    },

    registerCompany({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/companies/registration', 'POST', data);
    },

    // --------------------------------------------
    // PROFILE
    // --------------------------------------------

    getProfile({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }) {
        return new Promise((resolve, reject) => {
            request(dispatch, "/user", "GET")
                .then(result => {
                    setUser(resolve, commit, result);
                })
                .catch(err => {
                    reject(err);
                })
        });
    },
    getGlobalConfiguration({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }) {
        return new Promise((resolve, reject) => {
            request(dispatch, "/user/configuration?", "GET")
                .then(result => {
                    setConfiguration(resolve, commit, result);
                })
                .catch(err => {
                    reject(err);
                })
        });
    },

    getUserNotifications({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }) {
        return new Promise((resolve, reject) => {
            request(dispatch, "/user/notification?limit=" + process.env.VUE_APP_TOPBAR_NOTIFICATIONS_TOTAL, "GET")
                .then(result => {
                    setNotifications(resolve, commit, result);
                })
                .catch(err => {
                    reject(err);
                })
        });
    },
    saveUserProfile({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/user', 'PUT', data);
    },

    createPreconfiguredView({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/user/configuration', 'POST', data);
    },
    editPreconfiguredView({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/user/configuration/' + data.nameSlug, 'PUT', { ...data.data });
    },
    removePreconfiguredView({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/user/configuration/' + data.nameSlug, 'DELETE');
    },

    // inactivated reasons
    loadInactivatedReasons({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }) {
        return request(dispatch, '/inactivatedReasons', 'GET');
    },

    // motif hiring reasons
    loadHiringReasons({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }) {
        return request(dispatch, '/hiringReasons', 'GET');
    },

    // deleted reasons
    loadDeletedReasons({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }) {
        return request(dispatch, '/deletedReasons', 'GET');
    },

    // jobs
    getLastMissionsJobs({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }) {
        return request(dispatch, '/jobs/last', 'GET');
    },

    // degree levels
    loadDegreeLevels({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }) {
        return request(dispatch, '/degreeLevels', 'GET');
    },

    // compagnies
    loadCompanies({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, '/companies', 'GET', null, params);
    },
    loadCompany({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, companyId: string) {
        return request(dispatch, '/companies/' + companyId, 'GET', null);
    },
    editCompany({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/companies/' + data._id, 'PUT', data);
    },
    createCompany({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/companies/', 'POST', data);
    },
    sendCompaniesDocument({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/companies/insert', 'POST', data);
    },
    lastCompaniesMissions({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }) {
        return request(dispatch, '/companies/last/10', 'GET');
    },
    searchCompanies({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, query: string) {
        return request(dispatch, '/companies/search?name=' + query, 'GET');
    },
    getCompanyCalendar({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, '/companies/calendar', 'GET', null, params);
    },
    noAgencyMatches({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, missionId: string) {
        return request(dispatch, '/missions/' + missionId + '/noAgencyMatches', 'POST');
    },
    getEvoliaCompany({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: string) {
        return request(dispatch, '/companies/get-evolia-company', 'POST', data);
    },
    importEvoliaCompany({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: string) {
        return request(dispatch, '/companies/import-from-evolia', 'POST', data);
    },

    // interims
    loadInterims({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, '/interims', 'GET', null, params);
    },
    loadInterim({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/' + data.interimId, 'GET', null, data.query);
    },
    editInterim({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/' + data._id, 'PUT', data);
    },
    createInterim({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/', 'POST', data);
    },
    loadInterimMissions({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/' + data.interimId + '/' + data.missionId, 'GET');
    },
    loadInterimLastMissions({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, interimId: string) {
        return request(dispatch, '/missions/completed/' + interimId, 'GET');
    },
    loadInterimsToBeInactive({ dispatch, commit}: {dispatch: Dispatch, commit: Commit}, params: any) {
        return request(dispatch, '/interims/toBeInactive', 'GET', null, params);
    },
    sendInterimInvitationOne({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/' + data.interimId + '/inviteOne', 'POST');
    },
    sendInterimInvitation({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/inviteAll', 'POST');
    },
    resendInterimInvitationOne({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/' + data.interimId + '/inviteOneAgain', 'POST');
    },
    resendInterimInvitation({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/inviteAllAgain', 'POST');
    },
    sendInterimDocument({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/insert', 'POST', data);
    },
    getInterimUnavailabilities({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/' + data.interimId + '/unavailabilities', 'GET', null, data.ranges);
    },
    saveUnavailability({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/' + data.interimId + '/unavailabilities', 'PUT', data.data);
    },
    saveLongUnavailability({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/' + data.interimId + '/unavailabilities/long', 'PUT', data.data);
    },
    disableInterim({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, interimId: string) {
        return request(dispatch, '/interims/' + interimId, 'PUT', { disable: true });
    },
    disableInterimGroup({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, interimIds: string[]) {
        return Promise.all(interimIds.map((interimId: string) => request(dispatch, '/interims/' + interimId, 'PUT', { disable: true })));
    },
    removeInterim({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/' + data.interimId, 'DELETE', { reason: data.reason, reasonComplement: data.reasonComplement });
    },
    removeInterimGroup({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        const interimIds = data.interimIds;
        return Promise.all(interimIds.map((interimId: string) => request(dispatch, '/interims/' + interimId, 'DELETE', { reason: data.reason, reasonComplement: data.reasonComplement })));
    },
    interimNoLongerRegistered({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, interimId: string) {
        return request(dispatch, '/interims/' + interimId + '/no-longer-registered', 'PUT');
    },
    interimAgainRegistered({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, interimId: string) {
        return request(dispatch, '/interims/' + interimId + '/register-again', 'PUT');
    },
    loadInterimJobs({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, params : any) {
        return request(dispatch, '/interims/jobs', 'GET', null, params);
    },
    changeInterimActivity({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/' + data.interimId + '/status', 'PUT', { status: data.status, inactivityReason: data.reason, reasonComplement: data.reasonComplement });
    },
    changeInterimActivityGroup({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/status', 'POST', data);
    },
    getInterimStats({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, interimId: string) {
        return request(dispatch, '/interims/' + interimId + '/stats', 'GET');
    },
    getInterimsAvailabilities({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, '/interims/planning', 'GET', null, params);
    },
    validateInterimChange({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/'+ data.interimId + '/askedChangesValidation', 'POST', data);
    },
    getEvoliaInterim({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/get-evolia-interim', 'POST', data);
    },
    importEvoliaInterim({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/synchronize-with-evolia', 'POST', data);
    },
    getEvaluations({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/interims/' + data.interimId + '/evaluations', 'GET', null);
    },

    // agency
    loadAgency({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, agencyId: string) {
        return request(dispatch, '/agencies/' + agencyId, 'GET', null);
    },
    editAgency({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/agencies/' + data.agencyId, 'PUT', data.data);
    },
    getAgencyCollaborators({ dispatch }: { dispatch: Dispatch, commit: Commit }) {
        return request(dispatch, '/agencies/collaborators', 'GET');
    },
    askAgency({ dispatch }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/mission/' + data.missionId + "/askAgency", 'GET');
    },
    loadAgencies({ dispatch }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/companies/agencies', 'GET', null, data);
    },
    loadAgencyFromCompany({ dispatch }: { dispatch: Dispatch, commit: Commit }, agencyId: string) {
        return request(dispatch, '/companies/agencies/' + agencyId, 'GET');
    },
    getStats({ dispatch }: { dispatch: Dispatch, commit: Commit }, query: any) {
        return request(dispatch, '/agencies/stats', 'GET', null, query);
    },

    //supervisor
    loadDeployment({ dispatch, commit }: { dispatch: Dispatch, commit: Commit },) {
        return request(dispatch, '/supervisor/deployment', 'GET');
    },
    loadStatistics({dispatch, commit}:{dispatch: Dispatch, commit: Commit }, query:any) {
        return request(dispatch, '/supervisor/statistics', 'GET', null, query);
    },
    downloadStatistics({ dispatch, commit }: { dispatch: Dispatch; commit: Commit }, query: any) {
        return request(dispatch, "/supervisor/statistics/download", "GET", null, query);
    },

    //collabs
    listCollaborators({ dispatch }: { dispatch: Dispatch, commit: Commit }) {
        return request(dispatch, '/user/collaborators', 'GET');
    },
    createCollaborator({ dispatch }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/user/invite-collaborator', 'POST', data);
    },
    editCollaborator({ dispatch }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/user/' + data._id, 'PUT', data);
    },
    deleteCollaborator({ dispatch }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/user/' + data._id, 'DELETE');
    },

    //missions
    getMission({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, missionId: string) {
        return request(dispatch, '/missions/' + missionId, 'GET');
    },
    createMission({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/missions/', 'POST', data);
    },
    updateMission({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/missions/' + data.missionId, 'PUT', data.data);
    },
    deleteMission({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, missionId: string) {
        return request(dispatch, '/missions/' + missionId + "/delete", 'POST', {});
    },
    cancelMission({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/missions/' + data.missionId + "/cancel", 'POST', data.data);
    },
    sendCommandMission({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/missions/' + data.missionId + "/" + data.command, 'POST', data.data || {});
    },
    getMissions({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, '/missions/', 'GET', null, params);
    },
    getArchivedMissions({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, '/missions/archived', 'GET', null, params);
    },
    getMissionsByStatus({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, '/missions/status', 'GET', null, params);
    },
    searchMission({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/missions/' + data.missionId + '/interims', 'POST', data.data);
    },
    searchCandidates({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/missions/' + data.missionId + '/candidates', 'POST', data.data);
    },
    getCopyMissions({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, '/missions/copy', 'GET', null, params);
    },
    loadMissionAgencies({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/missions/' + data.missionId + '/agencies', 'POST', data?.data || {});
    },
    loadPresentationMission({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, token: string) {
        return request(dispatch, '/presentation/' + token, 'GET');
    },
    sendPresentationCommand({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/presentation/' + data.command, 'POST', data.data);
    },
    missionViewed({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, missionId: string) {
        return request(dispatch, '/missions/' + missionId + "/viewed", 'PUT');
    },
    removeInterimFromMissions({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/missions/removeInterim/' + data.interimId, 'DELETE', { reason: data.reason, reasonComplement: data.reasonComplement });
    },

    // notifications
    loadNotifications({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, query: any) {
        return request(dispatch, '/notifications', 'GET', null, query);
    },
    readNotifications({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, notificationId: string) {
        return request(dispatch, '/notifications/' + notificationId + '/read', 'PUT');
    },

    //jobOffer
    loadJobOffers({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, filter: any) {
        return request(dispatch, '/joboffers', 'GET', null, filter);
    },
    loadOldJobOffers({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, filter: any) {
        return request(dispatch, '/joboffers/old', 'GET', null, filter);
    },
    createJobOffer({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: IJobOffer) {
        return request(dispatch, '/joboffers', 'POST', data);
    },
    createJobOfferMission({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: IJobOffer) {
        return request(dispatch, '/joboffers/mission', 'POST', data);
    },
    getJobOffer({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, jobOfferId: string) {
        return request(dispatch, '/joboffers/' + jobOfferId, 'GET');
    },
    editJobOffer({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: IJobOfferDB) {
        return request(dispatch, '/joboffers/' + data._id, 'PUT', data);
    },
    postServiceJobOffer({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/joboffers/' + data.jobOfferId + "/command/" + data.toggle, 'POST', data.data);
    },
    getMessagesTemplates({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/joboffers/message', 'GET', null, data);
    },
    sendCommandJobOffer({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/joboffers/' + data.jobOfferId + "/command/" + data.command, 'POST', data.data || {});
    },
    addCandidateToJobOffer({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/joboffers/' + data._id +'/worker', 'POST', data.data);
    },
    getJobOfferWorkers({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, { jobOfferId, ...data }: any) {
        return request(dispatch, '/joboffers/' + jobOfferId + '/workers', 'GET', null, data);
    },
    updateJobOfferWorkerStatus({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/joboffers/' + data.jobOfferId + '/worker/' + data.workerId + '/status/' + data.newStatus, 'PUT')
    },
    loadTalentplugData({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }){
        return request(dispatch, '/talentplug/data', 'GET');
    },

    // candidates
    getCandidate({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, candidateId: string) {
        return request(dispatch, '/candidates/' + candidateId, 'GET');
    },
    getCandidates({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, '/candidates', 'GET', null, params);
    },
    editCandidate({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: ICandidateDB) {
        return request(dispatch, '/candidates/' + data._id, 'PUT', data);
    },
    createCandidate({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: ICandidate) {
        return request(dispatch, '/candidates', 'POST', data);
    },
    getCandidatesByStatus({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, '/candidates/status', 'GET', null, params);
    },
    sendCommandCandidate({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/candidates/' + data.candidateId + "/" + data.command, 'POST', data.data || {});
    },
    updateCandidate({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/candidates/' + data.candidateId, 'PUT', data.data);
    },
    analyseCandidate({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/candidates/analysis/resume', 'POST', data.data);
    },
    getAnalyseStatusCandidate({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/candidates/' + data.candidateId + '/analysis/status', 'GET');
    },
    updateCustomFieldsCandidate({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/candidates/' + data.candidateId + '/analysis/validation', 'PUT', data.data);
    },
    getCandidatesAgenda({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, '/candidates/agenda', 'GET', null, params);
    },
    getNewCandidateCount({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }) {
        return request(dispatch, '/candidates/count', 'GET', null);
    },

    // Identification
    findDuplicated({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, '/identification', 'GET', null, params);
    },
    workerDeduplicate({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/identification/worker-de-duplicate', 'POST', data);
    },
    searchWorker({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/identification/search-worker', 'POST', data);
    },
    shareWorker({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/identification/share-worker', 'POST', data);
    },
    candidatesToIdentificate({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/identification/candidates-to-identificate', 'GET');
    },

    // Synchronization
    companySynchronization({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, companyId: string) {
        return request(dispatch, '/companies/synchronize/' + companyId, 'GET');
    },
    allCompaniesSynchronization({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, agencyCode: string[]) {
        return request(dispatch, '/companies/allCompaniesSynchro', 'POST', { "agencyCode": agencyCode });
    },
    getAllCompaniesSynchronizationStatus({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, agencyCode: string[]) {
        return request(dispatch, '/companies/allCompaniesSynchroStatus', 'POST', { "agencyCode": agencyCode });
    },
    interimSynchronization({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, interimId: string) {
        return request(dispatch, '/interims/synchronize/' + interimId, 'GET');
    },
    allInterimsSynchronization({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, agencyCode: string[]) {
        return request(dispatch, '/interims/allInterimsSynchro', 'POST', { "agencyCode": agencyCode });
    },
    getAllInterimsSynchronizationStatus({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, agencyCode: string[]) {
        return request(dispatch, '/interims/allInterimsSynchroStatus', 'POST', { "agencyCode": agencyCode });
    },

    // Logger
    loadLogs({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/logger/find', 'POST', data)
    },
    editLogs({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/logger/edit', 'POST', data)
    },
    addNoteLogger({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, '/logger/add-note', 'POST', data)
    },
};

export default {
    namespaced: true,
    actions,
};

// PRIVATES ------------------

function fake(obj: any, key?: string) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (key) {
                resolve({ [key]: obj });
            } else {
                resolve(obj);
            }
        }, 200);
    });
}

function request(dispatch: Dispatch, to: string, method: string, data?: any, params?: any, responseType: string = "json") {
    return dispatch(
        "send",
        {
            to,
            method,
            data,
            params,
            responseType
        },
        { root: true }
    );
}

function setUser(resolve: any, commit: Commit, result: any) {
    commit("setUser", result, { root: true });
    resolve(result);
}

function setConfiguration(resolve: any, commit: Commit, result: any) {
    commit("setConfiguration", result, { root: true });
    resolve(result);
}

function setNotifications(resolve: any, commit: Commit, result: any) {
    commit("setNotifications", result, { root: true });
    resolve(result);
}
