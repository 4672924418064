import Vue from "vue";
import App from "./App.vue";
// import auth from "@snark/auth-vue";
import VueMq from "vue-mq";
import Transitions from "vue2-transitions";
import VModal from "vue-js-modal";
import { VTooltip, VPopover, VClosePopover } from "v-tooltip";
import VueTheMask from "vue-the-mask";
import VueScrollTo from "vue-scrollto";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import * as VueGoogleMaps from "vue2-google-maps";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
// import 'vue-toast-notification/dist/theme-sugar.css';
import PortalVue from "portal-vue";

import router from "./router";
import store from "./store";
import i18n from "./i18n/i18n";
import "./helpers/filters";
import auth from "@snark/auth-vue";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VCalendar from "v-calendar";
import { createPinia, PiniaVuePlugin } from "pinia";

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.use(pinia as any);

Vue.use(PortalVue);
Vue.use(VueToast);
// @ts-ignore;
Vue.use(VueTelInput);
Vue.use(PerfectScrollbar);
Vue.use(VueScrollTo);
Vue.use(VueTheMask);
Vue.use(VueMq, {
    breakpoints: {
        mobile: 769,
        tablet: 1024,
        desktop: 1351,
        wide: 1500,
    },
});
Vue.use(VCalendar);

Vue.use(Transitions);
Vue.use(VModal, { dialog: true, dynamic: true, injectModalsContainer: true });
Vue.directive("tooltip", VTooltip);
Vue.directive("close-popover", VClosePopover);
Vue.directive("click-outside-component", {
    bind: function (el: any, binding: any, vnode: any) {
        el.clickOutsideEvent = function (event: any) {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unbind: function (el: any) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
    },
});
Vue.component("v-popover", VPopover);
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GMAP_KEY || "xxxxxx",
        libraries: "places,drawing,directions",
        region: "FR",
        language: "fr",
    },
});

Vue.use(auth, {
    router,
    store,
    api: {
        base: process.env.VUE_APP_API_URL,
    },
    routes: {
        login: {
            path: "/connexion",
            name: ROUTES.LOGIN,
            component: () => import(/* webpackChunkName: "login" */ "./views/Login.vue"),
        },
        resetPassword: {
            path: "/nouveau-mot-de-passe",
            name: ROUTES.RESETPASSWORD,
            component: () => import(/* webpackChunkName: "reset-password" */ "./views/ResetPassword.vue"),
        },
    },
});

// [PrimeVue integration] - START
import PrimeVue from "primevue/config";
import { default as TableColumn } from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import DataTable from "primevue/datatable";

// import 'primevue/resources/themes/lara-light-indigo/theme.css';
// import 'primevue/resources/primevue.min.css';

Vue.use(PrimeVue, { unstyled: true });
Vue.component("TableColumn", TableColumn);
Vue.component("ColumnGroup", ColumnGroup);
Vue.component("DataTable", DataTable);
// [PrimeVue integration] - END

import Pulse from "@/components/loader/Pulse.vue";
import Row from "@/components/grid/Row.vue";
import Column from "@/components/grid/Column.vue";

import FormContainer from "@/components/form/FormContainer.vue";
import FormView from "@/components/form/FormView.vue";
import Field from "@/components/form/Field.vue";
import AtsField from "@/components/ats/form/AtsField.vue";
import GlobalErrors from "@/components/form/GlobalErrors.vue";
import Error from "@/components/form/Error.vue";

import Action from "@/components/Action.vue";
import Card from "@/components/Card.vue";
import SuperTable from "@/components/SuperTable.vue";
import SuperTableAts from "@/components/ats/SuperTableAts.vue";
import Confirm from "@/components/modal/Confirm.vue";
import LinkLike from "@/components/LinkLike.vue";
import Icon from "@/components/Icon.vue";
import PopOver from "@/components/PopOver.vue";
import Success from "@/components/form/Success.vue";
import moment from "moment-timezone";
import { ROUTES } from "@/data";
import { extendMoment } from "moment-range";
extendMoment(moment);

Vue.component("pulse", Pulse);
Vue.component("row", Row);
Vue.component("column", Column);
Vue.component("action", Action);
Vue.component("card", Card);
Vue.component("super-table-ats", SuperTableAts);
Vue.component("super-table", SuperTable);
Vue.component("form-container", FormContainer);
Vue.component("form-view", FormView);
Vue.component("field", Field);
Vue.component("ats-field", AtsField);
Vue.component("global-errors", GlobalErrors);
Vue.component("error", Error);
Vue.component("confirm", Confirm);
Vue.component("link-like", LinkLike);
Vue.component("icon", Icon);
Vue.component("popover", PopOver);
Vue.component("success", Success);

function checkNotch(): void {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const ratio = window.devicePixelRatio || 1;
    const screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio,
    };

    // iPhone X Detection
    if (iOS && screen.width == 1125 && screen.height === 2436) {
        document.body.classList.add("iphonex");
    }
}

// Interceptor.define(router);

Vue.config.productionTip = false;

const locale = localStorage.getItem("locale");
if (locale) {
    moment.locale(locale);
    i18n.locale = locale;
} else {
    moment.locale(i18n.locale);
}

// if (!process.env.VUE_APP_API_URL!.match(/localhost/i)) {
// Sentry.init({
//     Vue,
//     environment: process.env.NODE_ENV,
//     dsn: "https://cb4ea4294de74a8f957779d1c70325cd@o4505226881466368.ingest.sentry.io/4505227201544192",
//     integrations: [
//         new Sentry.BrowserTracing({
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         }),
//         new Sentry.Replay(),
//     ],

//     // Performance Monitoring
//     tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE!), // Capture 100% of the transactions, reduce in production!

//     // Session Replay
//     replaysSessionSampleRate: parseFloat(process.env.VUE_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE!), // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: parseFloat(process.env.VUE_APP_SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE!),
//     trackComponents: ["Header", "Navigation", "Footer"],
//     hooks: ["create", "mount"], // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
// }

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
    beforeCreate() {
        checkNotch();
    },
}).$mount("#app");
