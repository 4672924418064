import Vue from "vue";
import moment from "moment-timezone";

import {capitalize, getNumeric} from "@/helpers/commons";

Vue.filter('tonnage', (value: number) => {
    return getNumeric(value) + "T";
});

Vue.filter('tonnagelong', (value: number) => {
    return getNumeric(value) + " tonne" + (value > 1 ? 's' : '');
});

Vue.filter('date', (value: number, format: string) => {
    return moment(value).format(format);
});

Vue.filter('time', (value: number, format: string) => {
    return moment(value).format(format);
});

Vue.filter('year', (value: number) => {
    return moment(value).year();
});

Vue.filter('temperature', (value: number | string) => {
    if (value === '-') {
        return value;
    }
    return getNumeric(value) + '°';
});

Vue.filter('capitalize', (value: string) => {
    return capitalize(value);
});

Vue.filter('ampere', (value: number) => {
    return getNumeric(value) + 'A';
});

Vue.filter('price', (value: number) => {
    return getNumeric(value) + '€';
});

Vue.filter('rate', (value: number) => {
    return getNumeric(value) + '%';
});

Vue.filter('siret', (value: string) => {
    return value.slice(0,3) + " " + value.slice(3,6) + " " + value.slice(6,9) + " " + value.slice(9,14);
});
